<script setup>
import { useResetPassword } from "./business_logic";
import { requestStatus } from "@/utils/constants";
import {
  fieldRequired,
  passwordRules,
  passwordConfirmation,
} from "@/utils/input_validation.js";
import Otp from "../otp/index.vue";
import LoadingButton from "@/components/utils/LoadingButton.vue";
import PasswordRulesMessage from "@/components/utils/PasswordRulesMessage.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import { computed, ref } from "vue";
import { useRouter } from "@/utils/vue_helpers";
import { loginRoute } from "@/router/routes";

const props = defineProps({
  hideBackToLogin: {
    type: Boolean,
    default: false,
  },
  phoneNumber: {
    type: [String, Number],
    default: undefined,
  },
  emailAddress: {
    type: String,
    default: undefined,
  },
});

const {
  resetPasswordData,
  setOtpData,
  otpData,
  resetPasswordRequestStatus,
  resetPassword,
  transitionClass,
  inOtp,
  inNewPassword,
  goToOtp,
  goToNewPassword,
  validateOtp,
  validatingOtp,
  otpInvalid,
  resetOtpStatusToInitial,
} = useResetPassword();

setOtpData({
  emailAddress: props.emailAddress,
  phoneNumber: props.phoneNumber,
});

const { router } = useRouter();

const errorResettingPassword = computed(() => {
  return resetPasswordRequestStatus.value === requestStatus.ERROR;
});

const resettingPassword = computed(() => {
  return resetPasswordRequestStatus.value === requestStatus.SENDING;
});

async function onVerifyOtpClicked(otp) {
  try {
    const token = await validateOtp({
      otp,
      otpIdentifier: otpData.idNumber,
    });
    resetPasswordData.resetPasswordToken = token;
    otpData.otpVerified = true;
    goToNewPassword();
  } catch (error) {
    console.log(error);
  }
}

const resetPasswordForm = ref(null);
function onResetPasswordClicked() {
  const formValid = resetPasswordForm.value.validate();
  if (formValid) {
    resetPassword();
  }
}

const passwordConfirmationRule = passwordConfirmation({
  passwordGetter: () => resetPasswordData.newPasswordModel,
});

function goToLogin() {
  router.value.push(loginRoute);
}
</script>

<template>
  <div class="pl-md-6">
    <transition :name="transitionClass" mode="out-in">
      <!-- OTP -->
      <v-container v-if="inOtp" key="otp" fluid>
        <v-row class="mb-md-4">
          <v-col cols="12">
            <div class="text-h6 primary--text text-center text-md-left">
              Reset Password
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <Otp
              :phoneNumber="otpData.phoneNumber"
              :countryCode="otpData.countryCode"
              :emailAddress="otpData.email"
              :idNumber="otpData.idNumber"
              usingIdNumber
              :verifying="validatingOtp"
              :otpInvalid="otpInvalid"
              :resetOtpStatusToInitialFn="resetOtpStatusToInitial"
              @verifyOtpClicked="onVerifyOtpClicked"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="!hideBackToLogin"
          class="mt-md-10"
          justify="center"
          justify-md="start"
        >
          <v-col cols="auto">
            <v-btn
              outlined
              rounded
              depressed
              block
              class="text-none"
              @click="goToLogin"
            >
              <v-icon left :small="$mobileBreakpoint">mdi-arrow-left</v-icon>
              <span class="text-caption text-md-body-2">Back To Login</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- New password -->
      <v-container v-else-if="inNewPassword" key="newPassword">
        <v-row class="mb-4 mb-md-8">
          <v-col cols="12">
            <div
              class="text-body-1 text-md-h6 text-center text-md-left primary--text"
            >
              Enter your new password
            </div>
          </v-col>
        </v-row>
        <v-form
          @keyup.native.enter="onResetPasswordClicked"
          ref="resetPasswordForm"
        >
          <v-row>
            <v-col md="6" cols="12">
              <PasswordRulesMessage>
                <PasswordInput
                  label="New Password"
                  v-model="resetPasswordData.newPasswordModel"
                  :rules="[fieldRequired, ...passwordRules]"
                ></PasswordInput>
              </PasswordRulesMessage>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6" cols="12">
              <PasswordInput
                label="New Password Confirmation"
                showPasswordLabel="Show Password Confirmation"
                v-model="resetPasswordData.confirmNewPasswordModel"
                :rules="[fieldRequired, passwordConfirmationRule]"
              >
              </PasswordInput>
            </v-col>
          </v-row>

          <v-row
            v-if="errorResettingPassword"
            justify="center"
            justify-md="start"
          >
            <v-col cols="auto" md="12" class="d-flex align-center">
              <div
                class="error--text text-caption text-subtitle-2 text-center text-md-left"
              >
                An error has occurred, please try again
              </div>
              <v-icon class="error--text ml-2">mdi-alert-circle</v-icon>
            </v-col>
          </v-row>

          <v-row justify="center" justify-md="start">
            <v-col md="4" cols="auto">
              <LoadingButton
                rounded
                block
                :class="{
                  'button-background-secondary-1 white--text':
                    !resettingPassword,
                }"
                :loading="resettingPassword"
                @click="onResetPasswordClicked"
              >
                <div class="d-flex align-center">
                  <span>Reset Password</span>
                </div>
                <template #loadingText> Resetting Password </template>
              </LoadingButton>
            </v-col>
          </v-row>
        </v-form>

        <v-row
          v-if="!hideBackToLogin"
          class="mt-4 mt-md-10"
          justify="center"
          justify-md="start"
        >
          <v-col cols="auto">
            <v-btn
              outlined
              rounded
              depressed
              block
              class="text-none"
              @click="goToLogin"
            >
              <v-icon left :small="$mobileBreakpoint">mdi-arrow-left</v-icon>
              <span class="text-caption text-md-body-2">Back To Login</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- Password reset success -->
      <v-container v-else>
        <v-row class="">
          <v-col cols="12">
            <div
              class="text-body-1 text-md-h6 primary--text text-center text-md-left"
            >
              Password reset successfully
            </div>
          </v-col>
        </v-row>

        <template v-if="!hideBackToLogin">
          <v-row justify="center" justify-md="start">
            <v-col cols="auto" md="12" class="d-flex align-center">
              <span
                class="success--text text-body-2 text-md-body-1 text-center text-md-left"
              >
                Your password has been updated, you may login
              </span>
              <v-icon
                v-if="!$mobileBreakpoint"
                :size="$mobileBreakpoint ? 24 : 32"
                class="success--text ml-2"
                >mdi-check-circle</v-icon
              >
            </v-col>
          </v-row>
          <v-row justify="center" justify-md="start">
            <v-col cols="auto" md="4">
              <router-link
                :to="{
                  path: '/auth/login',
                }"
                :style="{
                  textDecoration: 'none',
                }"
              >
                <!-- <v-btn
                  depressed
                  rounded
                  block
                  :class="[
                    'text-none  white--text',
                    'button-background-secondary-1',
                  ]"
                >
                  <span>Proceed to login</span>
                  <v-icon class="white--text" right>
                    <slot>mdi-login</slot>
                  </v-icon>
                </v-btn> -->
                <LoadingButton
                  rounded
                  block
                  :class="[
                    'text-none  white--text',
                    'button-background-secondary-1',
                  ]"
                >
                  <span>Proceed to login</span>
                  <v-icon class="white--text" right>
                    <slot>mdi-login</slot>
                  </v-icon>
                </LoadingButton>
              </router-link>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <span class="success--text">
                Your password has been updated
              </span>
              <v-icon size="32" class="success--text ml-2"
                >mdi-check-circle</v-icon
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-btn
                depressed
                rounded
                :class="[
                  'text-none  white--text',
                  'button-background-secondary-1',
                  'px-10',
                ]"
                @click="goToOtp"
              >
                <v-icon class="white--text" left>
                  <slot>mdi-arrow-left</slot>
                </v-icon>
                <span>Back</span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </transition>
  </div>
</template>
