<script setup>
import ResetPassword from "@/components/auth/reset_password/index.vue";
import { userProfile, userDataLoading } from "@/utils/vue_helpers";
import { computed } from "vue";

const showResetPasswordSection = computed(() => {
  if (userDataLoading.value) {
    return false;
  }
  return true;
});
</script>

<template>
  <div class="pb-4">
    <template v-if="showResetPasswordSection">
      <ResetPassword
        :style="[!$mobileBreakpoint && { paddingLeft: '0 !important' }]"
        :phoneNumber="userProfile.phone_number1"
        :emailAddress="userProfile.email_address"
        hideBackToLogin
      />
    </template>
    <template v-else>
      <v-container fluid>
        <v-row class="mb-4">
          <v-col cols="12">
            <div class="text-h6 primary--text">Reset Password</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-skeleton-loader type="text@2" class="skeleton-fill-width">
            </v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-skeleton-loader type="button" class="skeleton-fill-width">
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>
