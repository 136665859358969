import { reactive, onMounted, ref, computed } from 'vue'
import { requestStatus, INVALID_OTP } from "@/utils/constants";
import { useValidateOtp } from '../otp/business_logic';
import store from '@/store'
import { GET_OTP_DATA } from '@/store/getters/user.js'
import apiCall from '@/utils/api';
import { getHashedPassword } from '@/utils/functions';


export function useResetPassword() {
    const resetPasswordData = reactive({
        newPasswordModel: '',
        confirmNewPasswordModel: '',
        resetPasswordToken: null
    })

    const obscuredOtpData = reactive({
        phoneNumber: '',
        email: ''
    })

    const transitionClass = ref('slide-left')

    const views = {
        OTP: 1,
        NEW_PASSWORD: 2,
        SUCCESS: 3
    }
    const currentView = ref(views.OTP)
    const inOtp = computed(() => {
        return currentView.value === views.OTP
    })
    const inNewPassword = computed(() => {
        return currentView.value === views.NEW_PASSWORD
    })
    const inSuccess = computed(() => {
        return currentView.value === views.SUCCESS
    })

    function goToOtp() {
        transitionClass.value = 'slide-left'
        otpData.otpVerified = false
        currentView.value = views.OTP
    }

    function goToNewPassword() {
        transitionClass.value = 'slide-left'
        currentView.value = views.NEW_PASSWORD
    }

    function goToSuccess() {
        transitionClass.value = 'slide-left'
        currentView.value = views.SUCCESS
    }

    function clearResetPasswordDataOnMount() {
        onMounted(() => {
            resetPasswordData.newPasswordModel = ''
            resetPasswordData.confirmNewPasswordModel = ''
            resetPasswordData.resetPasswordToken = null
            currentView.value = views.OTP
        })
    }

    const otpData = reactive({
        phoneNumber: '',
        email: '',
        countryCode: '',
        idNumber: '',
        otpVerified: false
    })
    function setOtpData({
        phoneNumber,
        emailAddress
    }) {
        const storeOtpData = store.getters[GET_OTP_DATA]
        const phoneNumberReceivedAsProp = phoneNumber !== undefined 
        otpData.phoneNumber = phoneNumberReceivedAsProp ? phoneNumber : storeOtpData.phoneNumber
        const emailReceivedAsProp = emailAddress !== undefined
        otpData.email = emailReceivedAsProp ? emailAddress : storeOtpData.email
        otpData.countryCode = storeOtpData.countryCode
        otpData.idNumber = storeOtpData.idNumber
        otpData.otpVerified = true
    }

    const resetPasswordRequestStatus = ref(requestStatus.NOT_SENT)
    async function resetPassword() {
        const requestData = {
            token: resetPasswordData.resetPasswordToken,
            id_number: otpData.idNumber,
            new_password: getHashedPassword(resetPasswordData.newPasswordModel)
        }

        try {
            resetPasswordRequestStatus.value = requestStatus.SENDING
            const response = await apiCall({ url: '/api/client/reset-password', data: requestData, method: 'POST' })
            resetPasswordRequestStatus.value = requestStatus.COMPLETE
            goToSuccess()
        } catch (error) {
            console.log(error)
            resetPasswordRequestStatus.value = requestStatus.ERROR
        }
    }

    const { validatingOtp, validateOtp, otpInvalid, resetOtpStatusToInitial } = useValidateOtp()

    onMounted(() => {
        clearResetPasswordDataOnMount()
        // setOtpData({})
    })

    return {
        resetPasswordData,
        clearResetPasswordDataOnMount,
        setOtpData,
        otpData,
        resetPasswordRequestStatus,
        resetPassword,
        transitionClass,
        inOtp,
        inNewPassword,
        goToOtp,
        goToNewPassword,
        validateOtp,
        validatingOtp,
        otpInvalid,
        resetOtpStatusToInitial
    }
}